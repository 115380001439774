// import 'slick-carousel';

$(document).ready(function () {
    $('.js-accessibility-toggle').click(function (e) { 
        e.preventDefault();
        
        $('.js-accessibility').toggleClass('active');
    });

    $('.js-lang-toggle').click(function (e) { 
        e.preventDefault();
        
        $('.js-lang').toggleClass('active');
    });

    $('.js-navigation-toggle').click(function (e) { 
        e.preventDefault();
        
        $('.js-navigation').toggleClass('active');
    });

    $('.js-sliderSolucoes').on('init reInit afterChange', function () {   
        $('.slick-slide.slick-center').prev().addClass('slick-left');
        $('.slick-slide.slick-center').next().addClass('slick-right');
        instancesUpdate();
    }).on('beforeChange', function () {
        $('.slick-slide').removeClass('slick-left');
        $('.slick-slide').removeClass('slick-right');
    }).slick({
        infinite: false,
        slidesToShow: 1,
        initialSlide: 1,
        dots: false,
        arrows: true,
        prevArrow: `<button type='button' class='slick-prev'><svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5" cy="19.5" r="19.5" fill="#02353C"/><path d="M16.6848 19.4204C16.3794 19.7465 16.3794 20.2535 16.6848 20.5796L22.4572 26.743C22.7626 27.0691 22.7626 27.5761 22.4572 27.9021L22.0478 28.3393C21.7128 28.697 21.1452 28.697 20.8102 28.3393L13.5428 20.5795C13.2374 20.2535 13.2374 19.7465 13.5428 19.4204L20.8102 11.6607C21.1452 11.303 21.7128 11.303 22.0478 11.6607L22.4572 12.0979C22.7626 12.4239 22.7626 12.9309 22.4572 13.257L16.6848 19.4204Z" fill="white"/></svg></button>`,
        nextArrow: `<button type='button' class='slick-next'><svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="19.5" cy="19.5" r="19.5" transform="rotate(-180 19.5 19.5)" fill="#02353C"/><path d="M22.3152 19.5796C22.6206 19.2535 22.6206 18.7465 22.3152 18.4204L16.5428 12.257C16.2374 11.9309 16.2374 11.4239 16.5428 11.0979L16.9522 10.6607C17.2872 10.303 17.8548 10.303 18.1898 10.6607L25.4572 18.4205C25.7626 18.7465 25.7626 19.2535 25.4572 19.5796L18.1898 27.3393C17.8548 27.697 17.2872 27.697 16.9522 27.3393L16.5428 26.9021C16.2374 26.5761 16.2374 26.0691 16.5428 25.743L22.3152 19.5796Z" fill="white"/></svg></button>`,
        variableWidth: true,
        centerMode: true,
        swipe: false,
        responsive: [
            { breakpoint: 1200, settings: {
                variableWidth: false,
                centerMode: false,
                slidesToShow: 1,
                initialSlide: 0,
                adaptiveHeight: true
            }},
        ]
    });

    $('.js-equipePopup-open').click(function (e) { 
        e.preventDefault();
        
        const i = $(this).attr('data-item');
        const obj = equipeArr[i];

        const $slider = $('.js-equipePopup');

        let imagemHtml = '';
        if (obj.imagem && obj.imagem['url']) {
            imagemHtml = `<div class="imagem"><img src="${obj.imagem['url']}" alt="${obj.nome}" /></div>`;
        }

        $slider.find('.box-content').html(`${imagemHtml}<div class="texto"><h3>${obj.nome}</h3>${obj.resumo}</div>`);
        $slider.fadeIn();
    });

    $('.js-equipePopup-close').click(function (e) { 
        e.preventDefault();
        const $slider = $('.js-equipePopup');
        $slider.fadeOut();
    });
});